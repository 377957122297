<template>
  <div>
    <div class="tw-flex tw-h-screen tw-w-full">
      <div class="tw-hidden md:tw-block md:tw-w-1/6 lg:tw-w-1/3 tw-bg-gray-100 my-bg-pattern"></div>
      <div class="tw-w-full md:tw-w-5/6 lg:tw-w-2/3 px-body md:tw-px-0 tw-bg-white tw-h-full tw-flex tw-flex- tw-flex-col tw-justify-center tw-items-center">
        <BaseCard class="tw-p-0 md:tw-p-8 tw-shadow-none md:tw-shadow-lg tw-max-w-full md:tw-w-2/3 tw-flex-shrink-0 tw-mb-20 auth-card tw-overflow-x-hidden">
          <div>
            <h1 class="tw-text-4xl tw-text-center">Reset password</h1>
            <div class="tw-h-4 tw-block"></div>
            <transition name="slide-left" mode="out-in">
              <div v-if="loading">
                <p class="tw-text-center">Please wait...</p>
              </div>
              <div v-else-if="!canResetPassword || errors.token">
                <p class="tw-text-center" v-html="errors.token"></p>
              </div>
              <form
                v-else-if="canResetPassword"
                ref="login-form"
                id="login-form"
                method="post"
                action="/login"
                @submit.prevent="onSubmit"
              >
                <div class="tw-block tw-my-4 tw-shadow-md tw-bg-red-600 tw-text-white tw-px-4 tw-py-2 tw-rounded-sm" v-if="errors.auth">
                  {{ errors.auth }}
                </div>
                <div class="tw-mb-4">
                  <InputGroup
                    label="Password"
                    name="password"
                    type="password"
                    placeholder="password"
                    v-model.lazy="password"
                    :error="errors.password"
                  />
                </div>
                <div class="tw-mb-8">
                  <InputGroup
                    label="Confirm Password"
                    name="password-confirm"
                    type="password"
                    placeholder="password"
                    v-model.lazy="confirmPassword"
                    :error="errors.confirmPassword"
                  />
                </div>
                <div class="flex items-center justify-between">
                  <BaseButton
                    type="submit"
                    :text="btnText"
                    :disabled="!canSubmit || btnDisabled"
                  />
                </div>
              </form>
            </transition>
          </div>
        </BaseCard>
      </div>
    </div>
  </div>
</template>

<script>
import InputGroup from '@/components/InputGroup.vue';
import form from '@/modules/formHelper';
import { apiPost, apiGet } from '@/modules/apiHelper';
import { toBoolean } from '@/modules/dataHelper';
import authMixin from '@/mixins/auth';

export default {
  name: 'Login',
  components: {
    InputGroup,
  },
  mixins: [authMixin],
  data() {
    return {
      loading: true,
      btnDisabled: false,
      btnText: 'Reset password',
      token: undefined,
      password: undefined,
      confirmPassword: undefined,
      errors: {
        password: undefined,
        confirmPassword: undefined,
        token: undefined,
      },
    };
  },
  computed: {
    canSubmit() {
      return this.canResetPassword
        && form.isValidTextInput(this.password, true, form.passwordRegex)
        && form.passwordsMatch(this.password, this.confirmPassword);
    },
    canResetPassword() {
      return toBoolean(this.token);
    },
    formData() {
      const formData = new FormData();
      formData.set('password', this.password);
      formData.set('token', this.token);
      return formData;
    },
  },
  watch: {
    password(val) {
      if (!form.isValidTextInput(val, true, form.passwordRegex)) {
        this.errors.password = 'Password should be atleast 8 characters long';
      } else {
        this.errors.password = undefined;
      }
    },
    confirmPassword(val) {
      if (!form.passwordsMatch(this.password, val)) {
        this.errors.confirmPassword = 'Passwords don\'t match';
      } else {
        this.errors.confirmPassword = undefined;
      }
    },
  },
  methods: {
    onSubmit() {
      if (!this.canSubmit) {
        this.errors.request = 'Please type a valid email address';
        return false;
      }
      this.btnDisabled = true;
      this.btnText = 'working...';
      this.errors.request = undefined;

      return apiPost('password-token', this.formData, 5)
        .catch((err) => {
          console.warn('password verification error', err);
          this.$toasted.global.appError();
        })
        .then((res) => {
          this.btnText = 'reset password';
          this.btnDisabled = false;
          if (!res) return false;

          const { data } = res;
          if (data.error) {
            console.error(data.message);
            this.errors.request = data.message;
            this.$toasted.global.appError({ errorMessage: data.message, duration: 4000, position: 'top-right' });
            return false;
          }

          this.$toasted.success(data.message, {
            duration: 7000,
            position: 'bottom-right',
          });

          this.$store.dispatch('clearUserSession');

          setTimeout(() => {
            this.$router.push(
              {
                name: 'login',
              },
            );
          }, 1800);

          return true;
        });
    },
  },
  mounted() {
    const token = this.$route.query.t;
    if (!token) {
      this.loading = false;
      this.errors.token = 'We cannot validate your token. Check your email';
      return false;
    }
    this.errors.token = undefined;

    // verify the reset request
    return apiGet('reset-password', 3, {
      t: token,
    }, true, false)
      .catch((err) => {
        console.warn('password verification error', err);
        this.$toasted.global.appError();
        this.loading = false;
        this.errors.token = 'We cannot validate your token. <br>Please try again or request for a password rest again.';
      })
      .then((res) => {
        if (!res) return false;
        this.loading = false;

        const { data } = res;
        if (data.error) {
          // console.error(data.message);
          this.$toasted.global.appError({ errorMessage: data.message, duration: 4000, position: 'top-right' });
          this.errors.token = data.message;
          return false;
        }

        // success
        this.token = data.token;
        this.errors.token = undefined;
        return true;
      });
  },
};
</script>

<style>

</style>
